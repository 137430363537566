import React, {useContext, useState} from 'react';
import axios from 'axios';

import {useHistory} from 'react-router-dom';

import endPoints from '../../utils/endPoints';

import {Container, Scroller, Checkbox} from './styles';
import estrelaLogo from '../../assets/estrela_logo.png';
import {BsCheckLg} from "react-icons/bs";

import {sessionSet} from '../../session';
import {UserContext} from '../../context/userContext';
import LoadingScreen from '../../components/LoadingScreen';
import mixPanelTrack from "../../mixpanel";

interface TermsBoxProps {
  token: string,
  userId: number,
  showTerms: boolean,
}

const TermsBox: React.FC<TermsBoxProps> = ({token, userId, showTerms}) => {
  //Context
  const {getUser} = useContext(UserContext);
  //States
  const [accept, setAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  //Variáveis úteis
  const history = useHistory();
  
  const acceptTerms = async () => {
    setIsLoading(true);
    let url = endPoints.users + `/user_terms/${userId}`;
    axios.put(url, {user_terms: true}, {headers: {Authorization: token}}).then((response) => {
      sessionSet({estrela_af_tk: token});
      getUser();
      mixPanelTrack('user_accepted_terms_of_user', { action: 'accept', user_id: userId, accepted: new Date().toLocaleString() })
      window.location.reload();
    }).catch((e) => {
      console.log(e);
    })
  }
  
  const rejectTerms = () => {
    mixPanelTrack('user_rejected_terms_of_user', { action: 'reject', user_id: userId, reject_at: new Date().toLocaleString() })
    document.location.href = '/login'
  }
  
  return (
    <>
      <Container className={showTerms ? 'visible' : ''}>
        <LoadingScreen show={isLoading}/>
        <img src={estrelaLogo} alt='estrela-logo'/>
        <h1>TERMOS E CONDIÇÕES GERAIS DOS AFILIADOS ESTRELABET</h1>
        <Scroller>
          <p><strong>Este documento representa os Termos e Condições Gerais para
            adesão de empresa ou pessoa ao programa de AFILIADOS da
            ESTRELABET.</strong></p>
          <p>Para participar do programa de afiliados da ESTRELABET, o AFILIADO
            deverá: (i) caso seja pessoa jurídica, possuir autorização legal
            para
            exercer regularmente a atividade de intermediação de negócios (ou
            alguma das atividades indicadas no momento do seu cadastro); (ii)
            não
            se enquadrar nas situações de impedimento previstas em nossas
            políticas de integridade (políticas de segurança, contra lavagem de
            dinheiro e contra o financiamento de atos ilícitos e terrorismo
            previstas no site <a
              href="https://estrelabet.com/pb/page/aml-policy">https://estrelabet.com/pb/page/aml-policy</a>);
            (iii) ser titular dos direitos de propriedade intelectual
            necessários
            ao exercício de sua atividade (especialmente em sites, blogs e redes
            sociais); (iv) preencher todos os dados previstos em nosso cadastro;
            (v) enviar todos os documentos solicitados no momento do cadastro ou
            sempre que necessário; (vi) ler e aceitar os Termos e as Condições
            Gerais previstos neste documento; e (vii) aguardar o e-mail de
            aprovação enviado pela STARS. Caso ocorra alguma divergência nas
            informações e/ou nos documentos solicitados, o AFILIADO receberá um
            e-mail com instruções para: corrigir os dados, enviar novos
            documentos
            ou prestar eventuais esclarecimentos necessários ao cumprimento de
            nossas políticas de integridade e de segurança contra lavagem de
            dinheiro e financiamento de atos ilícitos e terrorismo.</p>
          <p><strong>DISPOSIÇÕES INICIAIS:</strong></p>
          <ol>
            <li><p>Após o cumprimento e aprovação de todas as condições acima,
              ao
              aceitar o presente termo, é celebrada a relação comercial entre o
              AFILIADO e a STARS INVESTMENTS N.V. (ou empresa por esta indicada
              no
              curso deste termo ou eventual anexo), sociedade com sede em
              Curaçao,
              titular da marca ESTRELABET e do site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a> (“STARS”).
            </p>
            </li>
            <li><p>O objetivo deste termo é regular a intermediação de clientes,
              por meio da divulgação e a valorização de serviços e jogos
              oferecidos pela STARS (sobretudo no site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a>), que
              será
              realizada do seguinte modo: O AFILIADO receberá um link e/ou cupom
              específico da marca ESTRELABET, que deverá ser utilizado com a
              única
              e exclusiva finalidade de captar novos usuários para o site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a>.</p>
            </li>
            <li><p>A STARS disponibilizará para o AFILIADO link ou cupom
              específico para direcionamento de clientes intermediados por este
              para o site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a>, bem
              como
              link ou relatório de controle periódico de vendas realizadas pelos
              clientes intermediados pelo AFILIADO para o site <a
                href="http://www.estrelabet.com/">www.estrelabet.com</a>;</p>
            </li>
            <li><p>A divulgação da marca ESTRELABET pelo AFILIADO não importará
              em
              vínculo associativo, societário ou trabalhista, sem qualquer
              subordinação ou hierarquia entre as partes, ou entre estas e seus
              sócios, empregados, colaboradores, sub afiliados e prepostos.</p>
            </li>
            <li><p>Define-se por <em>wallet</em>, para fins deste termo, a conta
              gráfica da ESTRELABET na qual os Afiliados recebem, armazenam e
              gerenciam os ganhos de suas comissões.</p>
            </li>
            <li><p>O período de divulgação da marca ESTRELABET e do site <a
              href="http://www.estrelabet.com">www.estrelabet.com</a> entre a
              STARS e o AFILIADO perdurar-se-á enquanto a <em>wallet</em> deste
              estiver ativa. Uma vez encerrada sua <em>wallet</em>,
              encerrar-se-á
              sua relação comercial, bem como a de seus sócios, empregados,
              colaboradores, sub afiliados e prepostos com a STARS.</p>
            </li>
            <li><p>O AFILIADO será remunerado por meio de comissões - Receita
              Líquida Positiva (Revenue Share) e/ou CPA (Custo Por Aquisição),
              cujas métricas e variações serão definidas pela STARS em proposta
              Comercial enviada e formalizada por e-mail. A STARS poderá, a seu
              exclusivo critério, dar incentivos ao AFILIADO em decorrência de
              seus resultados.</p>
            </li>
            <li><p>Para cálculo do pagamento das comissões da remuneração
              (<em>Revenue</em> <em>Share</em>), serão considerados como Receita
              Líquida Positiva da STARS os valores efetivamente apostados pelos
              contatos/fãs/seguidores intermediados pelo AFILIADO e que</p>
            </li>
          </ol>
          <p>usarem o seu link/cupom específico do AFILIADO de direcionamento e
            apostas no site <a
              href="http://www.estrelabet.com">www.estrelabet.com</a>, menos os
            valores das apostas pagas para esses mesmos contatos/fãs/seguidores
            intermediados pelo AFILIADO. A STARS se reserva o direito de rever o
            cálculo da Receita Líquida Positiva em caso de erro (com compensação
            no pagamento seguinte), bem como alterar a regra de cálculo, para
            rever as condições conforme metas comerciais, ou para incluir
            tributos, insumos ou outros custos que venham afetar a Receita
            Líquida
            Positiva.</p>
          <ol start="9">
            <li><p>Para pagamento de CPA, cada cliente novo que se cadastrar no
              site <a href="http://www.estrelabet.com">www.estrelabet.com</a>,
              que
              depositar um determinado valor e efetivamente apostar este
              determinado valor (QFTD - QUALIFIED, FIRST TIME DEPOSIT), a STARS
              pagará ao AFILIADO, uma única vez por cliente novo, no mês
              seguinte
              ao do depósito e aposta, o valor definido e formalizado via e-mail
              pela STARS.</p>
            </li>
            <li><p>O AFILIADO poderá realizar, no máximo, um saque por dia.</p>
            </li>
            <li><p>Caso o valor do saque seja acima de R$ 4.999,99 (quatro mil,
              novecentos e noventa e nove reais e noventa e nove centavos), o
              AFILIADO somente poderá realizar seu saque caso indique e cadastre
              em sua <em>wallet</em> uma pessoa jurídica da qual tenha
              participação societária direta e encaminhe a para a STARS em até
              05
              (cinco) dias úteis antes do vencimento, nota fiscal (ou invoice
              caso
              o AFILIADO tenha sede social fora do Brasil) emitida com
              fundamento
              nos dados previstos e aprovados pela STARS no relatório relativo
              ao
              link ou cupom específico do AFILIADO, sob pena de suspensão do
              termo
              e/ou do pagamento da remuneração. Uma vez utilizada nota fiscal
              (ou
              invoice caso o AFILIADO tenha sede social fora do Brasil), o saque
              deverá ser realizado apenas por pessoa jurídica, mediante a
              emissão
              de nota fiscal.</p>
            </li>
            <li><p>A critério da STARS, o pagamento da remuneração deste termo
              poderá ser realizado por meio de SWIFT EURO, observada a cotação
              do
              Euro verificada no dia anterior ao pagamento, motivo pelo qual o
              AFILIADO se obriga a indicar conta bancária de depósito compatível
              com essa forma de pagamento e a pagar diretamente ou mediante
              autorização de dedução no valor que lhe é devido, as tributos e/ou
              despesas que eventualmente a sua instituição bancária lhe cobrar
              para realizar a operação financeira.</p>
            </li>
            <li><p>Na remuneração acordada e devida ao AFILIADO pela STARS está
              incluída toda e qualquer remuneração, lucro, honorários,
              contrapartida, custos, gastos e despesas diretas e indiretas,
              tributos, encargos sociais, encargos trabalhistas e contribuições
              (federais, estaduais e/ou municipais) eventualmente devidos pelo
              AFILIADO em razão da relação comercial deste termo.</p>
            </li>
            <li><p>A remuneração do termo será paga pela STARS, por empresa ou
              instituição à sua ordem, observada a proporção que melhor aprouver
              à
              STARS para viabilizar o pagamento.</p>
            </li>
            <li><p>A STARS se reserva o direito de alterar qualquer parte deste
              termo, a qualquer momento, mediante encaminhamento de nova versão
              para o AFILIADO. Caso o AFILIADO aceite os novos Termos de
              Condições
              Gerais, ou mantenha-se prestando regularmente os mesmos serviços,
              sem oposição, estará vinculado às novas cláusulas e condições para
              todos os efeitos.</p>
            </li>
            <li><p>O conceito de STARS engloba a STARS e/ou qualquer outra
              sociedade que venha a ser constituída pela STARS ou por seus
              acionistas diretos ou indiretos para a obtenção da autorização
              para
              atuar como agente operador de apostas no território brasileiro
              através da marca ESTRELABET, nos termos estabelecidos pela Lei nº
              14.790/2023 e demais instrumentos de regulação por ela previstos
              (“Licença”). Para fins de clareza, é prerrogativa exclusiva da
              STARS, na qualidade de detentora dos direitos relativos à marca e
              ao
              domínio ESTRELABET, a escolha da estrutura jurídico-societária
              que,
              a seu exclusivo critério, seja a mais conveniente para a obtenção
              e
              operação da Licença através da marca ESTRELABET, desde que
              respeitados os termos da Regulamentação de Apostas (conforme a
              seguir definido). Na hipótese de a STARS constituir uma nova
              pessoa
              jurídica para atendimento dos requisitos de obtenção e operação da
              Licença (“Nova Sociedade”), a STARS comunicará ao AFILIADO sobre a
              cessão destes Termos e Condições Gerais para a Nova Sociedade,
              sem,
              no entanto, haver necessidade de prévia autorização expressa do
              AFILIADO.</p>
            </li>
          </ol>
          <p><strong>OBRIGAÇÕES DO AFILIADO:</strong></p>
          <ol start="17">
            <li>Durante o período de divulgação, o AFILIADO se obriga a:</li>
          </ol>
          <p>a) Realizar a divulgação da marca e prospecção de clientes para o
            site <a href="http://www.estrelabet.com">www.estrelabet.com</a>, com
            o
            direcionamento dos clientes intermediados apenas para o link ou
            cupom
            disponibilizado pela ESTRELABET;</p>
          <p>b) Prestar ao cliente intermediado, espontaneamente, todas as
            informações sobre o andamento do negócio, bem como todos os
            esclarecimentos e informações acerca dos riscos e do caráter
            aleatório
            das atividades de apostas esportivas, jogos adultos de
            entretenimento
            online (cassino online), <em>gambling</em>, <em>crash</em>
            <em>games</em> e <em>fantasy</em> <em>games</em>;</p>
          <p>c) Observar todas as orientações fornecidas pela STARS para
            divulgação da marca e do site desta e, na hipótese de produção de
            conteúdo para veiculação em mídias e redes sociais da STARS,
            produzir
            somente conteúdos originais e inéditos;</p>
          <p>d) Respeitar as políticas de integridade da STARS (políticas de
            segurança, contra lavagem de dinheiro e contra o financiamento de
            atos
            ilícitos e terrorismo, todas previstas no site <a
              href="http://www.estrelabet.com">www.estrelabet.com</a>), zelando
            para que tais políticas sejam também respeitadas pelos clientes
            intermediados pelo AFILIADO, sob pena de suspensão das contas dos
            clientes e/ou do próprio AFILIADO, até que as informações e
            documentos
            solicitados para cumprimento das políticas acima sejam apresentados
            e
            aprovados pelo setor de Compliance da STARS, bem observar toda a
            legislação que permeia os negócios da STARS e do AFILIADO,
            principalmente, mas não se limitando, normas de conformidade e
            marketing;</p>
          <p>e) Responsabilizar-se integral e unicamente por qualquer
            informação,
            publicação, mensagem publicitária, curso, propaganda ou similar, que
            realizar em suas redes sociais e/ou aplicativo, que implique
            promessa
            infundada ou exagerada de ganho, a promoção de atos ilícitos, ou que
            minimize sem fundamento os riscos de perda total dos valores
            alocados
            ou o caráter aleatório das atividades de apostas esportivas, jogos
            adultos de entretenimento online, <em>gambling</em>, <em>crash</em>
            <em>games</em> e <em>fantasy</em> <em>games</em>;</p>
          <p>f) Não intermediar clientes que sejam pessoas menores de 18 anos,
            pessoas incapazes ou que por algum motivo não estejam aptas ou não
            seja aconselhável a sua participação em jogos, ou disponibilizar
            site
            ou link que, direta ou indiretamente, direcione serviços ou jogos da
            ESTRELABET para essas pessoas;</p>
          <p>g) Não realizar, direta ou indiretamente (por meio de parentes até
            o
            3º grau dos sócios ou administradores do AFILIADO), jogos, apostas,
            palpites esportivos ou recreativos no site <a
              href="http://www.estrelabet.com">www.estrelabet.com</a>, sobretudo
            na hipótese de o AFILIADO ter relação profissional ou contratual
            direta ou indireta com equipe esportiva e/ou com atleta profissional
            vinculado a evento coberto ou disponibilizado no site <a
              href="http://www.estrelabet.com">www.estrelabet.com</a>;</p>
          <p>h) Não criticar e/ou difamar a ESTRELABET, bem como os seus
            produtos,
            serviços, sites ou aplicativos;</p>
          <p>i) Não praticar atos ilícitos e não fomentar ou promover atitudes,
            discursos, narrativas ou veiculação de sons e imagens que, de forma
            direta ou indireta, promovam ódio, ofensas, comportamentos
            antiéticos,
            discriminação de qualquer espécie, violação dos direitos humanos,
            prática de atos ilícitos, bullying ou qualquer forma de assédio;</p>
          <p>j) O AFILIADO foi escolhido por suas características pessoais,
            sendo
            a divulgação, portanto, de intuito personalíssimo. Desse modo, não
            poderá ser delegada a terceiros ou de algum modo realizada por outra
            pessoa por conta e ordem do AFILIADO sem o consentimento expresso da
            STARS, sem prejuízo do direito de a STARS ceder os direitos deste
            termo para empresa por esta indicada;</p>
          <p>k) Autorizar expressamente, neste ato, que a STARS promova o
            monitoramento das suas redes sociais, para fiscalização do
            cumprimento
            das obrigações assumidas pelo AFILIADO, e realizar as correções ou
            os
            ajustes em suas postagens em redes sociais, observadas as
            orientações
            recebidas da STARS;</p>
          <p>l) Manter a licitude e a regularidade das suas atividades,
            responsabilizando-se pelo pagamento de todos os tributos incidentes
            sobre o seu negócio (com a autorização da retenção dos valores
            devidos, conforme previsto em lei), bem como emitir os documentos
            necessários ao recebimento da remuneração (nota fiscal ou invoice –
            sobretudo caso o AFILIADO tenha sede social fora do Brasil),
            encaminhando-os à STARS até 05 (cinco) dias úteis antes do
            vencimento,
            sob pena de suspensão do pagamento da remuneração até a
            regularização;</p>
          <p>m) Manter atualizado o seu cadastro, com o encaminhamento para a
            STARS das informações e, caso o AFILIADO seja ou se torne pessoa
            jurídica, dos seguintes documentos atualizados: i) contrato/estatuto
            social e últimas alterações, procuração com outorga de poderes; ii)
            cartão de CNPJ (ou similar em caso de AFILIADO com sede social fora
            do
            Brasil); iii) dados bancários completos (nome do Banco, número do
            Banco, nome e número da Agência Bancária, número da conta corrente
            com
            dígito, número do CNPJ do titular da conta) com dados
            correspondentes
            exatamente com o disposto no cartão de CNPJ e na última alteração do
            contrato social, e iv) documentação do representante legal da
            empresa
            (RG/CNH, CPF, endereço, estado civil, e-mail e telefone), sob pena
            de
            bloqueio da <em>wallet</em> e encerramento motivado do presente
            termo
            por parte da STARS caso a documentação não seja apresentada em até
            10
            (dez) dias úteis após a solicitação;</p>
          <p>n) Explicitar em todas as comunicações que realizar para os
            clientes
            intermediados ou potenciais clientes, que tal comunicação é feita
            sem
            o conhecimento ou a participação da STARS e que quaisquer
            reclamações
            que o cliente pretenda fazer devem ser encaminhadas ao AFILIADO e
            não
            à STARS;</p>
          <p>o) Abster-se de veicular, divulgar ou dar publicidade a qualquer
            informação, publicação, mensagem publicitária, curso, propaganda ou
            similar, que realizar no momento da divulgação, que implique
            promessa
            infundada ou exagerada de ganho, a promoção de atos ilícitos, ou que
            minimize sem fundamento os riscos de perda total dos valores
            alocados
            ou o caráter aleatório das atividades de apostas esportivas, <em>gambling,
              cash games</em> e <em>fantasy</em> <em>games</em>,
            responsabilizando-se civil e criminalmente pelas consequências do
            descumprimento deste item;;</p>
          <p>p) Não usar marca, logotipo, nome, site, palavras-chave ou qualquer
            tipo de propriedade intelectual/industrial de titularidade da STARS
            ou
            de interveniente anuente ou empresa do mesmo grupo desta (se for o
            caso), inclusive imitações ou variações que possam causar confusão
            com
            as propriedades intelectuais/industriais da STARS, exceto para o fim
            de cumprir as obrigações previstas neste termo;</p>
          <p>q) Manter a STARS indene de qualquer responsabilidade pela prática
            de
            atos ilícitos (violadores da legislação ou deste termo), o que
            inclui,
            sem limitar, a prática de bullying, violação dos direitos humanos,
            atos de corrupção, atos de terrorismo, atos de lavagem de dinheiro,
            fraude contra o sistema financeiro, uso não autorizado de bens e
            direitos de propriedade intelectual da STARS ou de terceiros;</p>
          <p>r) Autorizar a retenção integral ou parcial do pagamento da
            remuneração do termo, para quitação do valor das perdas e danos
            (morais, patrimoniais, lucros cessantes e danos emergentes) causadas
            pelo AFILIADO ou pelos clientes por ele intermediados à STARS, bem
            como multas e penalidades previstas neste termo, devidas à STARS em
            decorrência do descumprimento das obrigações assumidas pelo
            AFILIADO;</p>
          <p><strong>CONSIDERAÇÕES FINAIS:</strong></p>
          <ol start="18">
            <li>São vedadas as seguintes ações de comunicação, de publicidade e
              de
              marketing pelo AFILIADO em favor da STARS que:
            </li>
          </ol>
          <p></p>
          <p>a) Veiculem afirmações enganosas sobre as probabilidades de ganhar
            ou
            os possíveis ganhos que os apostadores podem esperar;</p>
          <p>b) Apresentem a aposta como socialmente atraente ou contenham
            afirmações de celebridades ou influenciadores digitais que sugiram
            que
            o jogo contribui para o êxito pessoal ou social, ou melhoria das
            condições financeiras; </p>
          <p>c) Contem com a participação de crianças ou adolescentes ou que
            sejam
            a eles dirigidas; </p>
          <p>d) Utilizem mensagens de cunho sexual ou da objetificação de
            atributos físicos; </p>
          <p>e) Configurem apelo à intensificação ou ao exagero na prática de
            apostar; </p>
          <p>f) Promovam o uso do produto como meio de recuperar valores
            perdidos
            em apostas anteriores ou outras perdas financeiras; </p>
          <p>g) Contribuam, de algum modo, para ofender crenças culturais ou
            tradições do País, especialmente aquelas contrárias à aposta; </p>
          <p>h) Sugiram ou induzam à crença de que:</p>
          <p>i. apostar é um ato ou sinal de virtude, de coragem, de maturidade
            ou
            associado ao sucesso ou ao êxito pessoal ou profissional; </p>
          <p>ii. a abstenção de apostar é ato ou sinal de fraqueza ou associado
            a
            qualquer qualidade negativa; </p>
          <p>iii. a aposta pode constituir uma solução para problemas de ordem
            social, profissional ou pessoal; </p>
          <p>iv. a aposta pode constituir alternativa ao emprego, solução para
            problemas financeiros, fonte de renda adicional ou forma de
            investimento financeiro e; </p>
          <p>v. a habilidade, a destreza ou a experiência podem influenciar o
            resultado de um evento esportivo.</p>
          <ol start="19">
            <li>Esta relação comercial poderá ser encerrada a qualquer momento,
              mediante encaminhamento de notificação expressa, sem o pagamento
              de
              multa pelas partes.
            </li>
          </ol>
          <p>a) Em qualquer hipótese de encerramento da relação entre o AFILIADO
            e
            a STARS, salvo mediante comprovação de fraude, será devida ao
            AFILIADO
            a remuneração integral referente ao saldo presente em
            sua <em>wallet</em> até a data de encerramento. Somente após esta
            data
            a STARS poderá excluir a <em>wallet</em> do AFILIADO, momento em que
            haverá quitação ampla e total dos valores devidos ao AFILIADO.</p>
          <ol start="20">
            <li><p>As regras contidas no termo serão aplicadas enquanto o
              AFILIADO
              estiver divulgando a marca ESTRELABET e o site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a>,
              deixando
              de ser aplicadas automaticamente no momento em que houver o
              encerramento da <em>wallet</em>.</p>
            </li>
            <li><p>A STARS não assume qualquer responsabilidade pela prática de
              atos ilícitos (violadores da legislação ou deste termo) praticados
              pelo AFILIADO, o que inclui, sem limitar, a prática de bullying,
              violação dos direitos humanos, atos de corrupção, atos de
              terrorismo, atos de lavagem de dinheiro, fraude contra o sistema
              financeiro, uso não autorizado de bens e direitos de propriedade
              intelectual da STARS ou de terceiros.</p>
            </li>
            <li><p>O AFILIADO terá sua <em>wallet</em> automaticamente
              desativada
              em caso de promoção ou incentivo a fraude comprovada por meio da
              obtenção de benefício/vantagem de forma ilícita ou pelo não
              cumprimento de qualquer condição estabelecida pela STARS neste
              termo. Para validar este item, caracteriza-se como fraude o
              cadastramento de informações incorretas ou falsas, a participação
              de
              pessoas não elegíveis nos critérios estabelecidos neste termo;
              participações que tenham sido efetuadas a partir de métodos
              robóticos, automático, repetitivo, programado ou similar e que
              desrespeitem as políticas presentes no site <a
                href="http://www.estrelabet.com">www.estrelabet.com</a>.</p>
            </li>
            <li><p>O AFILIADO e a STARS se comprometem a tratar os dados de
              acordo
              com a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados) e se
              responsabilizam pelas violações que cometerem. A STARS encerrará a
              relação com o AFILIADO imediatamente em caso de violação de dados
              pessoais.</p>
            </li>
            <li><p>Ao aceitar o link provisório, o AFILIADO reconhece e concorda
              expressamente com a política de gestão de terceiros (KYP - Know
              Your
              Partner) adotada pela STARS. O AFILIADO declara estar ciente de
              que
              a não obtenção de aprovação pelo departamento de Compliance da
              STARS, resultará na sua imediata exclusão, no bloqueio de sua
              carteira digital (wallet) e na perda do direito ao recebimento de
              quaisquer valores.</p>
            </li>
            <li><p>O AFILIADO reconhece e concorda que plataformas e/ou páginas
              digitais e/ou online estão sujeitas a eventuais a instabilidades
              técnicas que podem afetar temporariamente a disponibilidade, o
              desempenho ou a funcionalidade dos serviços de intermediação. Em
              virtude da natureza tecnológica dos serviços, a STARS não pode
              garantir a ausência total de instabilidades técnicas, mas
              compromete-se a agir prontamente para resolver quaisquer problemas
              identificados. Portanto, ao aceitar este regulamento, o AFILIADO
              isenta a STARS de qualquer responsabilidade por eventuais
              prejuízos
              decorrentes de instabilidades técnicas, reconhecendo que tais
              eventos estão além do controle direto da STARS.</p>
            </li>
            <li><p>Este termo substitui todos os Termos e Condições Gerais
              anteriores do programa de afiliados da STARS.</p>
            </li>
            <li><p><strong>As partes expressamente convencionam que as omissões,
              dúvidas ou controvérsias que possam ser eventualmente suscitadas
              em
              virtude do disposto no presente termo deverão ser supridas ou
              resolvidas com amparo na legislação brasileira, por meio de
              Mediação
              Institucional, a ser instaurada de acordo com o Regulamento de
              Mediação da CAMINAS – Câmara Mineira de Mediação e Arbitragem, por
              mediador único, sorteado dentre os membros do Quadro de Mediadores
              da CAMINAS. Se a Mediação instaurada não obtiver êxito no prazo
              regulamentar, a controvérsia, por solicitação de qualquer das
              partes, será convertida em Arbitragem a ser instaurada de acordo
              com
              o Regulamento de Arbitragem da CAMINAS – Câmara Mineira de
              Mediação
              e Arbitragem, por 3 (três) árbitros, sendo um indicado por uma
              parte, outro por outra e o terceiro, presidente do Tribunal
              Arbitral, sorteado dentre os membros do quadro de árbitros da
              CAMINAS especializados no tema da arbitragem. A mediação e
              arbitragem serão conduzidas no idioma português, os custos do
              procedimento serão rateados entre as partes igualmente, não sendo
              devido o pagamento de honorários de sucumbência de uma parte a
              outra.</strong></p>
            </li>
          </ol>
          <p>(__) Como AFILIADO, declaro que li, que entendi e que aceito a
            cláusula arbitral escalonada acima, sem qualquer observação ou
            ressalva.</p>
          <p>(__) Como AFILIADO, declaro que li, que entendi e que aceito todos
            os
            Termos e Condições Gerais acima, sem qualquer observação ou
            ressalva.</p>
        
        </Scroller>
        <div className="checkbox-accept">
          <Checkbox onClick={() => {
            setAccept(!accept);
          }}>
            {accept && <BsCheckLg/>}
          </Checkbox>
          <span className="accept-text">Li e estou de acordo com os Termos e Condições.</span>
        </div>
        <button onClick={() => acceptTerms()}
                className={accept || !isLoading ? 'activated' : ''}
                disabled={!accept || isLoading}>CONTINUAR
        </button>
        <span onClick={() => rejectTerms()}
              className="cancel-text">Sair</span>
      </Container>
    </>
  )
}

export default TermsBox;
